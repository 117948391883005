        #logo {
            width: 12em;
        }
        
        .section {
            min-height: 100vh;
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }
        
        .hidden {
            position: absolute;
            display: block;
            z-index: 0;
            text-indent: -9999px;
        }
        
        .row {
            margin: 0 !important;
        }
        
        .align-bottom {
            display: flex;
            position: absolute;
            align-self: flex-end;
            bottom: 4em;
        }
        
        .align-bottom ul {
            display: flex;
        }
        
        .align-bottom li {
            padding: 1em 4em 0 0;
        }
        
        .align-bottom p {
            font-size: 1em;
            font-weight: 300;
        }
        
        #typewriter {
            padding-top: 9em;
            padding-left: 0em;
        }
        
        #typewriter>div {
            padding-left: 0;
        }
        
        .cover {
            background-color: #0a0a0a;
            color: floralwhite;
        }
        
        .cover>.row {
            padding: 0 4em;
        }
        
        .cover h3 {
            font-family: "Droid Serif", serif;
            font-style: normal;
            font-weight: 100;
            font-size: 6vh;
            line-height: 1.1em;
        }
        
        .cover h4 {
            /* font-family: 'Roboto', sans-serif; */
            font-size: 1.25em;
            font-weight: bold;
            display: flex;
            align-items: center;
            padding-bottom: 1.5em;
        }
        
        .cover h4::after {
            content: "";
            /* flex: 1; */
            margin-left: 1rem;
            width: 2.5em;
            height: 2px;
            background-color: floralwhite;
        }
        
        .cover h5 {
            font-size: 1.1em;
            font-weight: bold;
        }
        
        .cover p,
        .cover span {
            font-weight: lighter;
            font-size: 1.1em;
            padding-bottom: 1em;
            line-height: 1.3em;
        }
        
        .cover a {
            color: inherit;
            text-decoration: inherit;
        }
        
        .container h4 {
            padding-bottom: 0.9em;
        }
        
        #work h4 {
            font-family: "Droid Serif", serif;
            font-size: 3.5em;
            line-height: 38px;
            color: #3a3a3a;
            font-weight: 300;
        }
        
        #work .item-layer h4 {
            font-family: "Roboto", sans-serif;
            font-size: 1.6em;
        }
        
        #about {
            padding: 18vh 0 12em;
        }
        
        #about .container h4 {
            padding-bottom: 0.5em;
        }
        
        #about h4 {
            font-family: "Droid Serif", serif;
            font-size: 7vw;
            line-height: 1.1em;
            color: #3a3a3a;
            font-weight: 300;
        }
        
        .line-hack::after {
            content: "";
            /* flex: 1; */
            margin-left: 1rem;
            width: 2.5em;
            height: 2px;
            background-color: floralwhite;
        }
        
        #about h5 {
            font-size: 1.6em;
            font-weight: normal;
            padding-bottom: 0.8em;
        }
        
        #industries {
            background-color: #0a0a0a;
        }
        
        #industries .title {
            padding-bottom: 2.5em;
            font-family: "Droid Serif", serif;
            font-weight: normal;
            font-size: 3.5em;
            color: floralwhite;
        }
        
        #industries h5 {
            color: floralwhite;
            display: block;
            font-size: 1.5vw;
            padding-bottom: 0.2em;
        }
        
        #industries li {
            padding-bottom: 2.4vw;
        }
        
        #industries a {
            display: inline-block;
            line-height: 1.5em;
            color: blanchedalmond;
            font-size: 1.1vw;
            pointer-events: none;
        }
        
        .container li {
            /* font-weight: bold; */
            padding-bottom: 0.7em;
            font-size: 1.4em;
        }
        
        .section li {
            font-size: 1.1em;
        }
        
        .reset-padding {
            padding-left: 0 !important;
        }
        
        .footer {
            margin: 0 !important;
        }
        
        .footer>.row>.col {
            position: relative;
        }
        
        .hiring h5 {
            font-size: 1.3em;
            font-weight: bold;
            padding-bottom: 1em;
        }
        
        #contact {
            bottom: -2em;
        }
        
        #contact h3 {
            font-size: 2.2vw;
        }
        
        .contact-text {
            font-size: 1.4em;
            padding-top: 2em;
        }
        
        #contact h5 {
            font-weight: bold;
            padding-bottom: 1em;
        }
        
        #contact li {
            font-weight: bold;
            padding-bottom: 0.9em;
        }
        
        #contact p {
            font-weight: 100;
            font-size: 1.1em;
            padding-bottom: 0;
        }
        
        #contact li span {
            color: grey;
        }
        
        .hiring {
            padding-top: 5em;
        }
        
        #case-content strong {
            font-weight: bold;
        }
        
        #case-content section {
            padding-top: 50px !important;
        }
        
        .social-icons {
            position: absolute;
            bottom: 4em;
            right: 4em;
        }
        
        .social-icons a {
            display: inline-block;
        }
        
        .social-icons a:first-child {
            padding-right: 2em;
        }
        /*Cases*/
        
        #case-container h1 {
            font-family: "Droid Serif", serif;
            font-size: 7vw;
            line-height: 1.1em;
            color: #3a3a3a;
            font-weight: 300;
        }
        
        #case-container h2 {
            text-transform: none;
            font-weight: normal;
            padding-top: 2em;
            font-size: 1.5em;
        }
        
        #case-content {
            margin-top: 0 !important;
        }
        
        #case-content .section {
            min-height: 10vh !important;
        }
        
        .resp-img {
            width: 100%;
            height: fit-content;
            object-fit: cover;
        }
        
        #work .col-md-6 {
            padding: 0;
        }
        
        .swiper-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        #mobilehiring {
            display: none;
        }
        
        .mobile-only {
            display: none;
        }
        
        #case-container {
            margin-top: 3em !important;
            text-align: center;
        }
        
        #case-container h2 {
            font-size: 1.6vw !important;
            line-height: 1.5em !important;
            padding-top: 1.4em !important;
        }
        
        .title-style-1 h2 {
            font-size: 2em !important;
        }
        
        #next {
            min-height: 1em !important;
        }
        
        .fix-case-h {
            height: 570px;
        }
        
        .fix-img-h {
            height: 30em;
        }
        
        #work-grid {
            width: 960px;
            margin: 0 auto;
        }
        
        @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
            #about h5 {
                font-size: 1.5em;
            }
            #about li {
                font-size: 1em !important;
            }
            .case-title .brand {
                font-size: 1.3em;
            }
            .fix-img-h img {
                height: auto !important;
            }
            .fix-case-h {
                height: auto;
            }
            #case-container h2 {
                font-size: 5vw !important;
                padding-top: 0 !important;
            }
            #logo {
                width: 30vw;
            }
            .mobile-hidden {
                position: absolute !important;
                visibility: hidden;
            }
            .align-bottom {
                position: static;
            }
            #cover .align-bottom {
                position: absolute;
                bottom: 8vh;
            }
            #cover li {
                color: darkgrey;
            }
            #cover h3 {
                font-size: 8.5vw !important;
                line-height: 6.5vh;
            }
            #typewriter {
                padding-left: 0;
                padding-top: 11vh;
            }
            #cover h4 {
                font-size: 1em;
            }
            #cover h5 {
                font-size: 2vh;
                font-weight: 100 !important;
            }
            #cover .align-bottom li {
                padding-top: 0.5em;
            }
            .align-bottom ul {
                display: inline-flex;
            }
            #contact .align-bottom ul {
                display: block;
            }
            .align-bottom li {
                padding: 1em 2em 0 0;
            }
            .social-icons {
                left: 2.5em;
                bottom: 5em;
            }
            .cover h4::after {
                content: "";
                margin-left: 1rem;
                width: 2.5em;
                height: 1px;
                background-color: floralwhite;
            }
            .instagram {
                width: 18px;
            }
            .linkedin {
                width: 18px;
            }
            .hiring-list ul {
                display: flex;
            }
            #contact h3 {
                font-size: 7vw;
                padding-right: 2em;
                line-height: 1.4em;
            }
            #contact>.row {
                padding: 0 2.5em 5em;
            }
            .cover h4 {
                /* font-family: 'Roboto', sans-serif; */
                font-size: 1.4em;
                padding-bottom: 0.6em;
            }
            #mobilehiring {
                display: block;
            }
            #mobilehiring a {
                color: blanchedalmond;
            }
            .resp-img {
                height: auto;
            }
            .team-desc {
                height: 25% !important;
            }
            .team-desc {
                background: none !important;
            }
            .vertical-align {
                padding-left: 1em !important;
            }
            .team-item h4 {
                color: floralwhite !important;
            }
            .team-item span {
                display: none;
            }
            #industries h5 {
                line-height: 1em;
                font-size: 5.6vw;
            }
            #industries li {
                padding-bottom: 3em;
            }
            #industries .title {
                padding-bottom: 1.5em;
                line-height: 1em;
            }
            #industries a {
                font-size: 5vw;
            }
            .title {
                padding: 0.8em 0;
            }
            #about h4 {
                font-size: 15vw;
            }
            #about h5 {
                padding-bottom: 0;
            }
            #about li {
                font-size: 1.3em;
            }
            .service-item {
                padding: 1em 0px !important;
            }
        }
        /* Projetos */
        
        @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
            .typewriter {
                min-width: 70vw !important;
                display: block;
            }
            .cover>.row {
                padding: 0 3em;
            }
            .mobile-hidden {
                display: none;
            }
            .mobile-only {
                display: block;
            }
            .brand {
                padding-bottom: 0.5em;
            }
            #case-container {
                margin-top: 3em;
            }
            #case-container h1 {
                font-size: 10vw;
                padding-bottom: 0.2em;
            }
            #case-container h2 {
                padding: 0.5em 1.5em;
                font-size: 1.5em;
                line-height: 1.4em;
            }
            #case-content {
                text-align: justify;
            }
            .block-bg {
                height: 32vh !important;
            }
            .top-baner {
                min-height: auto !important;
                height: auto !important;
            }
            .video-iframe-mobile iframe,
            .img-mobile img {
                width: 80vw;
                height: auto;
                padding: 2em 0 2em;
            }
            #results {
                width: 100vw;
            }
            #results .container {
                width: 100%;
            }
            .sub-title {
                display: initial !important;
                text-align: center;
            }
            .sub-title:before {
                height: 0;
            }
            .rio-hide {
                display: none !important;
            }
        }
        
        #case-content .resp-img {
            height: auto;
        }
        
        #work {
            min-height: 80vh;
            height: auto !important;
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }
        
        #case-container-grid {
            width: 100%;
            padding: 7vh 4em;
        }
        
        #case-container-grid .grid {
            width: 100%;
            height: 40vh;
            display: flex;
        }
        
        #case-container-grid .case {
            height: 100%;
            background-size: cover;
            padding: 0;
        }
        
        #case-container-grid .team-item {
            height: 100%;
        }
        
        #industries {
            margin-top: 5vw;
        }
        
        #work h4 {
            padding-top: 1vh;
        }
        
        #services-list {
            display: flex;
            align-items: center;
        }
        /* Grid home */
        
        @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
            #work {
                min-height: auto !important;
                height: 238vh;
            }
            #work h4 {
                padding-top: 0;
            }
            #case-container-grid .case {
                width: 100%;
                background-position-y: center;
            }
            #case-container-grid .grid {
                display: inline-block;
                height: 30vh;
            }
            #case-container-grid .team-desc .vertical-align {
                margin-top: -1vh;
            }
            #case-container-grid .grid:last-child {
                margin-top: -1.3em !important;
            }
            #work .item-layer h4 {
                font-size: 5vw;
            }
        }
        
        .block-bg {
            height: 64vh;
            margin-top: 0 !important;
        }
        
        .block-bg .bg-wrap {
            top: 50px !important;
            left: 50px !important;
            right: 50px !important;
        }
        
        .white-mobile-layer {
            /* display: none !important; */
        }
        
        .hide-on-mobile {
            display: none !important;
        }
        
        .hide-el {
            display: none;
        }
        
        #case-content h2,
        #case-content h5 {
            font-weight: normal !important;
            color: #0e0e0e !important;
        }
        /* TABLET Portrait and Landscape */
        
        @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
            #case-container-grid .grid {
                display: block;
                height: 20vh;
            }
            #work h4 {
                color: white;
            }
            #work .item-layer {
                background: none;
            }
            #work .team-desc .vertical-align {
                padding-left: 1em;
            }
            #case-container-grid .case {
                background-position-y: 45%;
            }
            #industries h5 {
                font-size: 2.2vh;
            }
            #industries a {
                font-size: 1.8vh;
            }
            #industries .title {
                padding-top: 1.5em;
            }
            #about h4 {
                font-size: 11vw;
                padding-top: 14vh;
            }
            #contact .mobile-hidden {
                display: none;
            }
            #contact .mobilehiring {
                display: none;
            }
            #contact h3 {
                font-size: 4vh;
                padding-top: 8vh;
            }
            /* projetos */
            .block-bg {
                height: 36vh;
            }
            #case-container h2 {
                font-size: 3vw !important;
            }
            #case-content .mobile-hidden {
                display: none;
            }
            #case-content .mobile-only {
                display: block;
            }
            #case-content .sub-title {
                text-align: center;
            }
            #case-content .simple-text {
                padding: 1em
            }
            #case-content .simple-text.md p {
                padding: 1em 0
            }
            #case-content iframe {
                width: 80vw;
                height: 36vh !important;
                padding: 2em 0 2em;
            }
        }

@media (max-width: 1024px) and (orientation: landscape) {
    #cover {
        padding-bottom: 12vw !important;
    }

    #cover h3 {
        line-height: 0.9;
    }

    #case-container-grid .row.grid {
        height: 70vh;
    }

    #industries {
        margin-top: 2em;
    }
}